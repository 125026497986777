//jQuery.noConflict();
jQuery(document).ready(function ($) {


    $('.preloader-gif').fadeOut(); // will first fade out the loading animation 
    $('.preloader-slider').delay(350000).fadeOut('slow'); // will fade out the white DIV that covers the website. 
    $('.slider-principal').delay(350000).css({ 'overflow': 'visible' });
   



    // Carousel 

    $('.slide-principal').owlCarousel({
        pagination: false,
        nav: true,
        loop: true,
        margin: 0,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 1
            },
            1000: {
                items: 1
            }
        }

    });

    $('.slide-principal2').owlCarousel({
        pagination: true,
        nav: true,
        loop: true,
        margin: 0,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 1
            },
            1000: {
                items: 1
            }
        }

    });

    $('.slide-secundari').owlCarousel({
        pagination: true,
        nav: true,
        loop: true,
        margin: 0,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 1
            },
            1000: {
                items: 1
            }
        }

    });


    $('.novetats').owlCarousel({
        //pagination: false,
        nav: false,
        loop: false,
        dots: true,
        margin: 30,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            },
            1200: {
                items: 3
            },
        }
    });


    $('.slide-curriculums').owlCarousel({
        //pagination: false,
        nav: false,
        loop: false,
        dots: true,
        margin: 30,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            },
            1200: {
                items: 3
            },
        }
    });

    $('.galeria').owlCarousel({
        pagination: true,
        nav: true,
        loop: false,
        margin: 0,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 1
            },
            1000: {
                items: 1
            }
        }

    });

    $('.galeria-fotos').owlCarousel({
        pagination: true,
        nav: true,
        loop: false,
        margin: 0,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 1
            },
            1000: {
                items: 1
            }
        }

    });

    $('.relacionados').owlCarousel({
        pagination: true,
        nav: true,
        loop: false,
        margin: 30,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            1000: {
                items: 3
            }
        }

    });




    $(".owl-prev").html('<i class="ei-arrow_carrot-left"></i>');
    $(".owl-next").html('<i class="ei-arrow_carrot-right"></i>');


    //Go through each carousel on the page
    $('.owl-carousel').each(function () {
        //Find each set of dots in this carousel
        $(this).find('.owl-dot').each(function (index) {
            //Add one to index so it starts from 1
            $(this).attr('aria-label', index + 1);
        });
    });


    //Go through each carousel on the page
    $('.owl-carousel').each(function () {
        //Find each set of dots in this carousel
        $(this).find('.owl-dot').each(function (index) {
            //Add one to index so it starts from 1
            $(this).removeAttr('role');
        });
    });


    // apply meta info to next and previous buttons and make them focusable
    $('.owl-carousel').find('.owl-prev').attr('role', 'button').attr('title', 'Previous');
    $('.owl-carousel').find('.owl-next').attr('role', 'button').attr('title', 'Next');
    $('.owl-carousel, .owl-prev, .owl-next').attr('tabindex', '0');


    // Highlight the top nav as scrolling occurs
    $(window).scroll(function() {
        if ($(document).scrollTop() > 250) {
            $('.header').addClass('shrink');
        } else {
            $('.header').removeClass('shrink');
        }
    });




    // Anchor scroll
    var $root = $('html, body');


    // Select all links with hashes
    $('.menu-item a[href*="#"]')
        // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function(event) {
        // On-page links
        if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
            location.hostname == this.hostname
        ) {
            // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: target.offset().top
                }, 1000, function() {
                    // Callback after animation
                    // Must change focus!
                    var $target = $(target);
                    $target.focus();
                    if ($target.is(":focus")) { // Checking if the target was focused
                        return false;
                    } else {
                        $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                        $target.focus(); // Set focus again
                    };
                });
            }
        }
    });






    // Toast
    $('.toast').toast({

    });

    // Contact forms
    $('.wpcf7-form .wpcf7-text').addClass('form-control');
    $('.wpcf7-textarea').addClass('form-control');
    $('.wpcf7-date').addClass('form-control');
    $('.wpcf7-submit').addClass('btn btn-primary');

    // Responsive iframes
    $("iframe").wrap('<div class="embed-responsive embed-responsive-16by9"/>');
    $("iframe").addClass('embed-responsive-item');


    // Popup
    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,

        fixedContentPos: false
    });


});